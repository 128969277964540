<template>
	<div class="saving-screen fixed" v-if="isLoading">
		<span>Sayfa hazırlanıyor, lütfen bekleyin....</span>
	</div>
	<div class="saving-screen fixed" v-if="isCreating">
		<span>PDFler hazırlanıyor, lütfen bekleyin....</span>
	</div>
	<r-flex align="flex-end">
		<r-input v-model="realUrl" label="Sitede kullanılacak Url" width="30%" />
		<r-btn
			bgcolor="#ff1155"
			@click="copyHtml"
			:disabled="errorImages.length > 0"
		>
			HTML Kopyala
		</r-btn>
		<r-input v-model="startQuestion" label="PDF Başlangıç" width="10%" />
		<r-input v-model="endQuestion" label="PDF Bitiş" width="10%" />
		<r-btn
			bgcolor="#aa6600"
			@click="createPDF"
			:disabled="errorImages.length > 0"
		>
			PDF Oluştur
		</r-btn>
		<r-btn
			bgcolor="#aa6600"
			@click="createSeperatePDF"
			:disabled="errorImages.length > 0"
		>
			Parçalı PDF Oluştur
		</r-btn>
		<r-select
			v-if="store.state.usertype === 0 || store.state.usertype === 1"
			v-model="questPart"
			label="Soru Çeşidi"
			:data="questParts"
			txt="txt"
			val="val"
			width="15%"
			@change="getQuests"
		/>
		<r-select
			v-if="store.state.usertype === 2"
			v-model="catId"
			label="Kategoriler"
			:data="catList.data"
			txt="title"
			val="id"
			width="15%"
			@change="GetCategoryQuestions"
			style="color: rgb(17, 17, 17)"
		>
			<template v-slot:itemtxt="{ data }">
				{{ data.id + '_' + data.title }}
			</template>
		</r-select>
	</r-flex>
	<r-flex class="r-mb-16 page-header-C">
		<h3>Referanslar</h3>
	</r-flex>
	<r-flex>
		<h4 v-if="errorImages.length > 0" class="txt-error">
			PDF sayfalardaki resimlerin tamamı yüklenemedi. Lütfen kontrol edip tekrar
			export etmeyi deneyin. Etkilenen Sayfalar: {{ errorImages }}
		</h4>
	</r-flex>
	<r-flex v-if="loaded">
		<r-flex flx="7" align="center" class="all-reference-pages" v-if="loaded">
			<template v-for="(questref, m) in realList" :key="m">
				<references-pages
					:pagedata="questref.references"
					:qtype="questionType(questref.question)"
					:question="questref.question"
					:imagelist="imagesList['list' + questref.question.examNumber]"
					v-model:imagesloaded="pageImagesLoaded"
				/>
			</template>
		</r-flex>
		<div
			class="export-question-C"
			style="width: 100%; display: flex; flex-direction: column"
		>
			<template v-for="(quest, i) in firstList.data" :key="i">
				<div style="width: 100%; margin-bottom: 16px">
					<div style="display: flex" v-if="quest.question.isCase">
						<strong>{{ quest.question.examNumber }}.</strong>
						<div style="display: flex" class="r-mb-8">
							<div v-html="quest.question.case"></div>
						</div>
					</div>

					<div style="display: flex">
						<strong v-if="!quest.question.isCase">
							{{ quest.question.examNumber }}.
						</strong>
						<div v-html="quest.question.detail"></div>
						<br />
					</div>
					<div style="display: flex:flex-direction:column;padding-bottom:12px;">
						<template v-for="(choice, j) in quest.question.choiceList" :key="j">
							<div
								:style="{
									display: 'flex',
									color:
										store.state.choices[j] === quest.question.answer
											? 'red'
											: '',
								}"
							>
								<p style="width: auto; width: 20px">
									{{ store.state.choices[j] }})
								</p>
								<div v-html="choice"></div>
							</div>
						</template>
					</div>
					<div
						style="display: flex:flex-direction:column;"
						v-if="quest.references.length > 0"
					>
						<template v-for="(ref, k) in quest.references" :key="k">
							<div style="display: flex">
								<span style="color: blue">Referans: &nbsp;</span>
								<span style="color: red">{{ ref.title }}&nbsp;</span>
								<a
									style="color: green"
									target="_blank"
									:href="DownloadPdfUrl(quest, ref)"
								>
									Referansları görüntülemek için tıklayın
								</a>
							</div>
						</template>
					</div>
					<r-btn
						@click="createPartPDF(i)"
						class="r-mt-8 r-mb-32"
						:disabled="errorImages.length > 0"
					>
						PDF Oluştur
					</r-btn>
				</div>
			</template>
		</div>
	</r-flex>
</template>
<script>
	import { computed, onMounted, ref } from 'vue'
	import { useStore } from 'vuex'
	import { jsPDF } from 'jspdf'
	import html2canvas from 'html2canvas'
	import ReferencesPages from '../Referanslar/ReferencesPages'
	import { makeUrl } from '@/mix/global.js'
	export default {
		components: {
			ReferencesPages,
		},
		setup() {
			const store = useStore()
			const listData = ref({})
			const notesCatsList = ref({})
			const loaded = ref(false)
			const noReference = ref(false)
			const imagesList = ref({})
			const realUrl = ref('')
			const isCreating = ref(false)
			const isLoading = ref(true)
			const firstList = ref({})
			const listcnt = ref(0)
			const questPart = ref(0)
			const pageImagesLoaded = ref(true)
			const startQuestion = ref(0)
			const endQuestion = ref(0)
			const errorImages = ref([])
			const imagesLoaded = ref(false)
			const pageImages = ref([])
			const pdfImages = ref([])
			const catId = ref(null)
			const catList = ref({})
			const questionCount = ref(0)
			const contentEl = ref(null)
			const questParts = ref([
				{ txt: 'Temel Bilimler', val: 0 },
				{ txt: 'Klinik Bilimler', val: 1 },
			])
			const referenceImagesLoaded = () => {
				pageImages.value.forEach(img => {
					img.addEventListener('error', function() {
						if (
							errorImages.value.indexOf(img.getAttribute('data-imgquestion')) <
							0
						)
							errorImages.value.push(img.getAttribute('data-imgquestion'))
					})
				})
			}
			const realList = computed(() => {
				return listData.value.data
			})
			const questionType = q => {
				if (store.state.usertype === 0) {
					if (parseInt(q.examNumber) <= store.state.tustemelq)
						return 'Temel Bilimler'
					else return 'Klinik Bilimler'
				} else if (store.state.usertype === 1) {
					if (parseInt(q.examNumber) <= store.state.dustemelq)
						return 'Temel Bilimler'
					else return 'Klinik Bilimler'
				} else if (store.state.usertype === 2) {
					return q.categoryName
				}
			}
			const minQuest = computed(() => {
				if (store.state.usertype === 0 && parseInt(questPart.value) === 0)
					return 1
				else if (store.state.usertype === 0 && parseInt(questPart.value) === 1)
					return store.state.tustemelq + 1
				else if (store.state.usertype === 1 && parseInt(questPart.value) === 0)
					return 1
				else if (store.state.usertype === 1 && parseInt(questPart.value) === 1)
					return store.state.dustemelq + 1
				else if (store.state.usertype === 2) return 1
			})
			const maxQuest = computed(() => {
				if (store.state.usertype === 0 && parseInt(questPart.value) === 0)
					return store.state.tustemelq
				else if (store.state.usertype === 0 && parseInt(questPart.value) === 1)
					return 1000
				else if (store.state.usertype === 1 && parseInt(questPart.value) === 0)
					return store.state.dustemelq
				else if (store.state.usertype === 1 && parseInt(questPart.value) === 1)
					return 1000
				else if (store.state.usertype === 2) return questionCount.value
			})

			const newList = (list, quest) => {
				list.sort((a, b) => {
					return a.priority - b.priority
				})
				imagesList.value['list' + quest.examNumber] = []
				var newArr = []
				var listKeys = []
				var ind = 0
				if (list.length > 0) {
					list.forEach(itm => {
						if (listKeys.indexOf(itm.noteCategoryName) === -1) {
							listKeys.push(itm.noteCategoryName)
							let notcat = notesCatsList.value.data.filter(v => {
								return v.id === itm.noteCategoryId
							})[0]
							newArr.push({
								id: ind,
								title: itm.noteCategoryName,
								pagetitle: notcat.title,
								pagedesc: notcat.description,
								data: [itm],
							})
							imagesList.value['list' + quest.examNumber].push([])
							ind++
						} else {
							newArr[listKeys.indexOf(itm.noteCategoryName)].data.push(itm)
						}
					})
					newArr.forEach((itm, i) => {
						var subKeys = []
						var indi = 0
						itm.data1 = []
						itm.data.forEach(dr => {
							if (subKeys.indexOf(dr.userId) === -1) {
								subKeys.push(dr.userId)
								itm.data1.push({
									id: indi,
									title: dr.username,
									userid: dr.userId,
									data: [dr],
								})

								indi++
							} else {
								itm.data1[subKeys.indexOf(dr.userId)].data.push(dr)
							}
							dr.pid = i
							delete itm.data
						})
						itm.data1.forEach((dat, m) => {
							dat.data.forEach((pag, n) => {
								pag.pages[0].detail.forEach((drw, o) => {
									if (drw.isImg) {
										drw.iid = i
										drw.tid = m
										drw.pid = n
										drw.kid = o
										drw.url =
											'https://referans.tusdata.com/referansnotlari/' +
											store.state.workFolder +
											'/edited_ref_img_' +
											store.state.usertype +
											'/' +
											quest.categoryId +
											'/' +
											pag.lessonNoteId +
											'/' +
											drw.fileName +
											'.jpg'
										imagesList.value['list' + quest.examNumber][i].push(drw)
									}
								})
							})
						})
					})
				} else {
					imagesList.value['list' + quest.examNumber].push([])
				}
				imagesList.value = Object.assign({}, imagesList.value)
				list.splice(0, list.length, ...newArr)
				listcnt.value++
			}

			const createPDF = () => {
				isCreating.value = true
				setTimeout(() => {
					const doc = new jsPDF('p', 'px', [1000, 1415])
					var els = document.getElementsByClassName('pdf-screen-W-save')
					var newels = []
					els.forEach(el => {
						if (
							parseInt(el.getAttribute('data-question')) >=
								parseInt(startQuestion.value) &&
							parseInt(el.getAttribute('data-question')) <=
								parseInt(endQuestion.value)
						)
							newels.push(el)
					})
					var cnt = 0
					var imgCnt = 0
					var imgC = {}
					var intC = {}
					newels.forEach(async (el, i) => {
						html2canvas(el, {
							scale: 2.535,
							scrollX: 0,
							scrollY: 0,
							width: 700,
						}).then(function(canvas) {
							imgC['im' + i] = canvas.toDataURL('image/jpeg')
							intC['i' + i] = setInterval(() => {
								if (imgC['im' + i] !== '') {
									clearInterval(intC['i' + i])
									cnt++
								}
							}, 500)
						})
					})

					var saveImgInt = setInterval(() => {
						if (cnt === newels.length) {
							clearInterval(saveImgInt)
							for (let e = 0; e < newels.length; e++) {
								doc.addImage(imgC['im' + e], 'JPEG', 0, 0)
								imgCnt++
								if (imgCnt !== newels.length) doc.addPage()
								delete imgC['im' + e]
							}
						}
					}, 1000)

					var saveInt = setInterval(() => {
						if (imgCnt === newels.length) {
							clearInterval(saveInt)
							var docname =
								store.state.workFolder +
								'_' +
								store.state.usertype +
								'_' +
								startQuestion.value +
								'_' +
								endQuestion.value +
								'.pdf'
							if (store.state.usertype === 2)
								docname =
									store.state.workFolder +
									'_' +
									store.state.usertype +
									'_' +
									catId.value +
									'_' +
									startQuestion.value +
									'_' +
									endQuestion.value +
									'.pdf'

							doc.save(docname)
							isCreating.value = false
						}
					}, 1000)
				}, 50)
			}

			const createSeperatePDF = () => {
				isCreating.value = true
				setTimeout(() => {
					var noarr = 0
					var imgaC = {}
					var intaC = {}
					var nocnt = 0
					var docnoarr = {}
					listData.value.data.forEach(async arr => {
						if (
							parseInt(arr.question.examNumber) >=
								parseInt(startQuestion.value) &&
							parseInt(arr.question.examNumber) <= parseInt(endQuestion.value)
						) {
							if (arr.references.length > 0) {
								arr.references.forEach((pag, j) => {
									const doc = new jsPDF('p', 'px', [1000, 1415])
									var els = document.getElementsByClassName(
										'sep-page-save-' + arr.question.examNumber + '-' + j
									)
									var newels = []
									els.forEach(el => {
										if (
											parseInt(el.getAttribute('data-question')) >=
												parseInt(startQuestion.value) &&
											parseInt(el.getAttribute('data-question')) <=
												parseInt(endQuestion.value)
										)
											newels.push(el)
									})
									var cnt = 0
									var imgCnt = 0
									var imgC = {}
									var intC = {}
									newels.forEach(async (el, i) => {
										await html2canvas(el, {
											scale: 2.535,
											scrollX: 0,
											scrollY: 0,
											width: 700,
										}).then(function(canvas) {
											imgC['im' + i] = canvas.toDataURL('image/jpeg')
											intC['i' + i] = setInterval(() => {
												if (imgC['im' + i] !== '') {
													clearInterval(intC['i' + i])
													cnt++
												}
											}, 500)
										})
									})

									var saveImgInt = setInterval(() => {
										if (cnt === newels.length) {
											clearInterval(saveImgInt)
											for (let e = 0; e < newels.length; e++) {
												doc.addImage(imgC['im' + e], 'JPEG', 0, 0)
												imgCnt++
												if (imgCnt !== newels.length) doc.addPage()
												delete imgC['im' + e]
											}
										}
									}, 1000)

									var int = setInterval(() => {
										if (imgCnt === newels.length) {
											clearInterval(int)
											var docname =
												store.state.workFolder +
												'_' +
												store.state.usertype +
												'_' +
												arr.question.examNumber +
												'_' +
												makeUrl(pag.title) +
												'.pdf'
											if (store.state.usertype === 2)
												docname =
													store.state.workFolder +
													'_' +
													store.state.usertype +
													'_' +
													catId.value +
													'_' +
													arr.question.examNumber +
													'_' +
													makeUrl(pag.title) +
													'.pdf'

											doc.save(docname)
											isCreating.value = false
										}
									}, 1000)
								})
							} else {
								noarr++
								var el = document.getElementsByClassName(
									'sep-page-save-' + arr.question.examNumber + '-0'
								)[0]
								docnoarr['doc-' + arr.question.examNumber] = new jsPDF(
									'p',
									'px',
									[1000, 1415]
								)
								await html2canvas(el, {
									scale: 2.535,
									scrollX: 0,
									scrollY: 0,
									width: 700,
								}).then(function(canvas) {
									imgaC['im' + arr.question.examNumber] = canvas.toDataURL(
										'image/jpeg'
									)
									intaC['i' + arr.question.examNumber] = setInterval(() => {
										if (imgaC['im' + arr.question.examNumber] !== '') {
											clearInterval(intaC['i' + arr.question.examNumber])
											delete intaC['i' + arr.question.examNumber]
											docnoarr['doc-' + arr.question.examNumber].addImage(
												imgaC['im' + arr.question.examNumber],
												'JPEG',
												0,
												0
											)
											nocnt++
											delete imgaC['im' + arr.question.examNumber]
										}
									}, 500)
									var inta = setInterval(() => {
										if (nocnt === noarr) {
											clearInterval(inta)

											Object.keys(docnoarr).forEach(k => {
												let examNum = k.split('-')
												var docname =
													store.state.workFolder +
													'_' +
													store.state.usertype +
													'_' +
													examNum[1] +
													'.pdf'
												if (store.state.usertype === 2)
													docname =
														store.state.workFolder +
														'_' +
														store.state.usertype +
														'_' +
														catId.value +
														'_' +
														examNum[1] +
														'.pdf'
												docnoarr[k].save(docname)
												delete docnoarr[k]
											})
											isCreating.value = false
										}
									}, 1000)
								})
							}
						}
					})
				}, 50)
			}

			const DownloadPdfUrl = (q, r) => {
				if (store.state.usertype === 2) {
					return (
						realUrl.value +
						'/' +
						store.state.workFolder +
						'_' +
						store.state.usertype +
						'_' +
						catId.value +
						'_' +
						q.question.examNumber +
						'_' +
						makeUrl(r.title) +
						'.pdf'
					)
				} else {
					return (
						realUrl.value +
						'/' +
						store.state.workFolder +
						'_' +
						store.state.usertype +
						'_' +
						q.question.examNumber +
						'_' +
						makeUrl(r.title) +
						'.pdf'
					)
				}
			}
			const createPartPDF = async x => {
				isCreating.value = true
				setTimeout(() => {
					if (listData.value.data[x].references.length > 0) {
						listData.value.data[x].references.forEach((pag, j) => {
							const doc = new jsPDF('p', 'px', [1000, 1415])
							var els = document.getElementsByClassName(
								'sep-page-save-' +
									listData.value.data[x].question.examNumber +
									'-' +
									j
							)
							var cnt = 0
							var imgCnt = 0
							var imgC = {}
							var intC = {}
							els.forEach(async (el, i) => {
								await html2canvas(el, {
									scale: 2.535,
									scrollX: 0,
									scrollY: 0,
									width: 700,
								}).then(function(canvas) {
									imgC['im' + i] = canvas.toDataURL('image/jpeg')
									intC['i' + i] = setInterval(() => {
										if (imgC['im' + i] !== '') {
											clearInterval(intC['i' + i])
											cnt++
										}
									}, 500)
								})
							})
							var saveImgInt = setInterval(() => {
								if (cnt === els.length) {
									clearInterval(saveImgInt)
									for (let e = 0; e < els.length; e++) {
										doc.addImage(imgC['im' + e], 'JPEG', 0, 0)
										imgCnt++
										if (imgCnt !== els.length) doc.addPage()
										delete imgC['im' + e]
									}
								}
							}, 1000)
							var int = setInterval(() => {
								if (imgCnt === els.length) {
									clearInterval(int)
									var docname =
										store.state.workFolder +
										'_' +
										store.state.usertype +
										'_' +
										listData.value.data[x].question.examNumber +
										'_' +
										makeUrl(pag.title) +
										'.pdf'
									if (store.state.usertype === 2)
										docname =
											store.state.workFolder +
											'_' +
											store.state.usertype +
											'_' +
											catId.value +
											'_' +
											listData.value.data[x].question.examNumber +
											'_' +
											makeUrl(pag.title) +
											'.pdf'
									doc.save(docname)
									isCreating.value = false
								}
							}, 1000)
						})
					} else {
						var rdy = false
						const doc = new jsPDF('p', 'px', [1000, 1415])
						var el = document.getElementsByClassName(
							'sep-page-save-' +
								listData.value.data[x].question.examNumber +
								'-0'
						)[0]
						html2canvas(el, {
							scale: 2.535,
							scrollX: 0,
							scrollY: 0,
							width: 700,
						}).then(function(canvas) {
							var img = canvas.toDataURL('image/jpeg')
							var imgint = setInterval(() => {
								if (img !== '') {
									clearInterval(imgint)
									doc.addImage(img, 'JPEG', 0, 0)
									rdy = true
								}
							}, 500)
							var inta = setInterval(() => {
								if (rdy) {
									clearInterval(inta)
									var docname =
										store.state.workFolder +
										'_' +
										store.state.usertype +
										'_' +
										listData.value.data[x].question.examNumber +
										'.pdf'
									if (store.state.usertype === 2)
										docname =
											store.state.workFolder +
											'_' +
											store.state.usertype +
											'_' +
											catId.value +
											'_' +
											listData.value.data[x].question.examNumber +
											'.pdf'

									doc.save(docname)
									isCreating.value = false
								}
							}, 1000)
						})
					}
				}, 50)
			}
			const getQuests = async e => {
				loaded.value = false
				questPart.value = e.val
				imagesList.value = Object.assign({})
				isLoading.value = true
				listData.value = await getList()
				SetQuestionValue()
				firstList.value.data.sort((a, b) => {
					return (
						parseInt(a.question.examNumber) - parseInt(b.question.examNumber)
					)
				})
				setTimeout(() => {
					listForPdf()
				}, 100)
			}
			const SetQuestionValue = () => {
				if (store.state.usertype === 0) {
					if (questPart.value === 0) {
						startQuestion.value = 1
						endQuestion.value = 100
					} else {
						startQuestion.value = 101
						endQuestion.value = 200
					}
				}
				if (store.state.usertype === 1) {
					if (questPart.value === 0) {
						startQuestion.value = 1
						endQuestion.value = 40
					} else {
						startQuestion.value = 41
						endQuestion.value = 120
					}
				}
				if (store.state.usertype === 2) {
					startQuestion.value = 1
					endQuestion.value = questionCount.value
				}
			}
			const getList = async d => {
				let params = {
					max: maxQuest.value,
					min: minQuest.value,
					workmonth: store.state.workMonth,
					workyear: store.state.workYear,
					isImg: true,
				}
				let api = 'references/getallreferences'
				if (store.state.usertype === 2 && d !== undefined) {
					params.categoryId = d
					api = 'references/getallreferencesbycategory'
				}
				let list = await store.dispatch('getDatas', {
					api: api,
					params: params,
				})
				var noref = 0
				firstList.value = list
				list.data.forEach(itm => {
					if (itm.references.length === 0) noref++
				})
				if (list.data.length === noref) noReference.value = true
				return list
			}
			const GetCategoryQuestions = async d => {
				loaded.value = false
				imagesList.value = Object.assign({})
				isLoading.value = true
				catId.value = d.id
				questionCount.value = await getQuestionCount()
				maxQuest.value
				SetQuestionValue()
				listData.value = await getList(d.id)
				firstList.value.data.sort((a, b) => {
					return (
						parseInt(a.question.examNumber) - parseInt(b.question.examNumber)
					)
				})
				setTimeout(() => {
					listForPdf()
				}, 100)
			}
			const getCatList = async () => {
				let list = await store.dispatch('getDatas', {
					api: 'categories/getsubcategories',
					params: {
						pid: 0,
					},
				})

				return list
			}
			const getNoteCatsList = async () => {
				let list = await store.dispatch('getDatas', {
					api: 'notes/getnotecategories',
				})
				return list
			}
			const getQuestionCount = async () => {
				let list = await store.dispatch('getDatas', {
					api: 'categories/getquestioncount',
					params: {
						categoryid: catId.value,
					},
				})
				return list.data
			}
			const copyHtml = () => {
				var el = document.querySelector('.export-question-C')
				var cln = el.cloneNode(true)
				var div = document.createElement('div')
				div.appendChild(cln)
				var btns = div.getElementsByTagName('button')
				while (btns[0]) btns[0].parentNode.removeChild(btns[0])
				div.innerHTML = div.innerHTML.replace(/<!--.*?-->/g, '')
				var txta = document.createElement('textarea')
				txta.value = div.innerHTML
				txta.style.top = '0'
				txta.style.left = '100%'
				txta.style.position = 'fixed'
				txta.style.opacity = '0'
				txta.style.width = '0'
				txta.style.height = '0'
				document.body.appendChild(txta)
				txta.focus()
				txta.select()
				document.execCommand('copy')
				document.body.removeChild(txta)
				cln.remove()
				div.remove()
			}
			const listForPdf = () => {
				listData.value.data.sort((a, b) => {
					return (
						parseInt(a.question.examNumber) - parseInt(b.question.examNumber)
					)
				})
				listcnt.value = 0
				var imgcnt = 0
				pdfImages.value = []
				listData.value.data.forEach(quest => {
					newList(quest.references, quest.question)
				})
				var int = setInterval(() => {
					if (listcnt.value === listData.value.data.length) {
						clearInterval(int)
						loaded.value = true
						pdfImages.value = document.getElementsByClassName('pdf-img-n')
						pageImages.value = document.getElementsByClassName(
							'referans-img-tmp'
						)
					}
				}, 200)

				var imagesInt = setInterval(() => {
					if (pageImages.value.length > 0 || pdfImages.value.length === 0) {
						clearInterval(imagesInt)
						referenceImagesLoaded()
					}
				}, 200)

				var intimg = setInterval(() => {
					if (
						(loaded.value === true && pdfImages.value.length > 0) ||
						(loaded.value === true && noReference.value === true)
					) {
						pdfImages.value.forEach(img => {
							if (img.complete) {
								imgcnt = imgcnt + 1
							}
						})
					}
				}, 200)
				var intimgload = setInterval(() => {
					if (
						(pdfImages.value.length > 0 && imgcnt >= pdfImages.value.length) ||
						noReference.value === true
					) {
						clearInterval(intimg)
						clearInterval(intimgload)
						isLoading.value = false
					}
				}, 200)
			}
			const WindowResized = e => {
				contentEl.value.style.minHeight = e.target.innerHeight - 126 + 'px'
			}
			onMounted(async () => {
				await store.dispatch('getWorkFolder')
				notesCatsList.value = await getNoteCatsList()

				if (store.state.usertype === 0 || store.state.usertype === 1) {
					listData.value = await getList()
				} else if (store.state.usertype === 2) {
					catList.value = await getCatList()
					catId.value = catList.value.data[0].id
					questionCount.value = await getQuestionCount()
					listData.value = await getList(catList.value.data[0].id)
				}

				SetQuestionValue()
				firstList.value.data.sort((a, b) => {
					return (
						parseInt(a.question.examNumber) - parseInt(b.question.examNumber)
					)
				})
				setTimeout(() => {
					listForPdf()
				}, 300)
				contentEl.value = document.getElementById('content-C')
				contentEl.value.style.minHeight = window.innerHeight - 126 + 'px'
				window.addEventListener('resize', WindowResized)
			})
			return {
				store,
				listData,
				loaded,
				newList,
				createPDF,
				createSeperatePDF,
				createPartPDF,
				realList,
				firstList,
				imagesList,
				questionType,
				makeUrl,
				realUrl,
				copyHtml,
				isCreating,
				isLoading,
				questPart,
				questParts,
				getQuests,
				pageImagesLoaded,
				startQuestion,
				endQuestion,
				errorImages,
				imagesLoaded,
				GetCategoryQuestions,
				catId,
				catList,
				DownloadPdfUrl,
			}
		},
	}
</script>
<style>
	.export-question-C {
		width: 100%;
	}
	.all-reference-pages {
		position: fixed;
		opacity: 0;
		left: 100%;
		top: 0;
	}
	.txt-error {
		background-color: crimson;
		color: #fff;
		padding: 16px;
		border-radius: 4px;
	}
</style>
